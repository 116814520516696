import ApiService from "@/common/api.service";

export default {
  createReport(data) {
    return ApiService.post("class-progress-report", data);
  },
  createGroupClassReport(data) {
    return ApiService.post("group-class-progress-report", data);
  },
  fetchReports(classId) {
    return ApiService.query("class-progress-reports", { class_id: classId });
  },
  fetchReport(reportId) {
    return ApiService.query(`class-progress-report/${reportId}`);
  },
  fetchTeacherReportStatuses(q, page) {
    return ApiService.query("teachers/progress-reports-status", { q, page });
  },
  updateReport(report) {
    return ApiService.patch("class-progress-report", report);
  },
  deleteClassReport(classReportId) {
    return ApiService.delete(`class-progress-report/${classReportId}`);
  },
  deleteStudentClassReport(studentClassReportId) {
    return ApiService.delete(`student-progress-report/${studentClassReportId}`);
  }
};
