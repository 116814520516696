<template>
  <div class="container">
    <Breadcrumb>
      <template slot="lastPage">
        <IsOnlineIcon :isOnline="sessionClass.course_session.is_online" />
        {{ sessionClass.title }}
      </template>
    </Breadcrumb>
    <Heading heading="h4" :content="sessionClass.title">
      <div slot="button">
        <IsOnlineIcon
          class="mr-3"
          :isOnline="sessionClass.course_session.is_online"
        />
        <router-link
          v-if="isRoleAdmin()"
          :to="{
            name: 'privateClassesEdit',
            params: { privateType: 'counseling', class_id: $route.params.id }
          }"
        >
          <el-button type="primary" size="small">
            <i class="fas fa-edit" />
          </el-button>
        </router-link>
      </div>
    </Heading>
    <hr />
    <div v-show="loading">
      <CounselingsState
        :meets="
          Object.keys(lessonLogs).length === 0 ? [] : lessonLogs[studentUserId]
        "
        :reports="
          Object.keys(studentReports).length === 0
            ? []
            : studentReports[studentUserId]
        "
        :counselingId="Number($route.params.id)"
        :studentUserId="studentUserId"
      />
      <template v-if="this.student && !hasSurvey">
        <el-alert
          class="mt-4 mb-4"
          v-if="isRoleStudent() || isRoleParent() || isRoleAdmin()"
          title="為了給您提供更好的服務，希望您能抽出幾分鐘時間，將您的感受和建議告訴我們，我們非常重視每位用戶的寶貴意見，期待您的參與！"
          type="info"
          :closable="false"
          show-icon
          effect="dark"
        >
          <router-link
            :to="{ name: 'Survey', query: { student: studentUserId } }"
            class="survey-link"
          >
            立即前往填寫問卷調查
          </router-link>
        </el-alert>
      </template>
      <hr />
      <template v-if="this.student && hasSurvey">
        <!-- <el-alert
          v-if="isRoleStudent() || isRoleParent() || isRoleAdmin()"
          class="mt-4 mb-4"
          title="您已完成問卷調查，感謝您的參與！"
          type="success"
          :closable="false"
          show-icon
        >
          <router-link
            :to="{ name: 'Survey', query: { student: studentUserId } }"
            class="survey-link"
          >
            點此查看或修改問卷
          </router-link>
        </el-alert> -->
        <el-card class="mb-4">
          <Heading :content="$t('survey.Background')" heading="h4">
            <router-link
              slot="button"
              :to="{ name: 'Survey', query: { student: studentUserId } }"
              class="survey-link"
            >
              <el-button type="primary" size="mini">
                <i class="fa fa-edit"></i>
              </el-button>
            </router-link>
          </Heading>
          <hr />
          <div class="survey-questions" v-if="student">
            <div v-for="(question, index) in questions" :key="index">
              <div class="d-flex">
                <div>
                  <h2>
                    <b>
                      {{ question.question }}
                    </b>
                  </h2>
                  <!-- <p>{{ question.description }}</p> -->
                  <div v-html="student[question.prop]"></div>
                </div>
              </div>
              <hr />
            </div>
          </div>
        </el-card>
      </template>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import IsOnlineIcon from "@/components/icon/IsOnline";
import CounselingsState from "@/views/private/CounselingsState/Index";
import privateApi from "@/apis/private";
import reportApi from "@/apis/report";
import roleMixin from "@/mixins/role";
import profileApi from "@/apis/profile";

export default {
  metaInfo() {
    return {
      title: `${this.isOnlineText} ${this.sessionClass.title ||
        ""} - Ivy-Way Academy`
    };
  },
  mixins: [roleMixin],
  components: {
    Breadcrumb,
    IsOnlineIcon,
    CounselingsState
  },
  watch: {
    students() {
      if (this.$store.getters["user/isRole"]("parent")) {
        this.currenctLessonTab = String(this.students[0].student.id);
      }
    }
  },
  computed: {
    hasSurvey() {
      let hasSurvey = false;
      if (
        this.student &&
        (this.student.ideal_college_major ||
          this.student.dream_schools ||
          this.student.current_situation ||
          this.student.how_can_we_help ||
          this.student.ideal_counselor ||
          this.student.availability_to_meet)
      ) {
        hasSurvey = true;
      }
      return hasSurvey;
    },
    isOnlineText() {
      switch (this.sessionClass.is_online) {
        case 1:
          return "Online";
        case 0:
          return "In-person";
        default:
          return "";
      }
    },
    studentUserId() {
      if (this.students.length === 0) {
        return null;
      } else {
        return this.students[0].student_user_id;
      }
    }
  },
  data() {
    return {
      loading: false,
      questions: [
        {
          question: this.$t("survey.Q1"),
          description: this.$t("survey.Q1-text"),
          prop: "ideal_college_major",
          answer: ""
        },
        {
          question: this.$t("survey.Q2"),
          description: this.$t("survey.Q2-text"),
          prop: "dream_schools",
          answer: ""
        },
        {
          question: this.$t("survey.Q3"),
          description: this.$t("survey.Q3-text"),
          prop: "current_situation",
          answer: ""
        },
        {
          question: this.$t("survey.Q4"),
          description: this.$t("survey.Q4-text"),
          prop: "how_can_we_help",
          answer: ""
        },
        {
          question: this.$t("survey.Q5"),
          description: this.$t("survey.Q5-text"),
          prop: "ideal_counselor",
          answer: ""
        },
        {
          question: this.$t("survey.Q6"),
          description: this.$t("survey.Q6-text"),
          prop: "availability_to_meet",
          answer: ""
        }
      ],
      student: null,
      students: [],
      lessonLogs: {},
      studentReports: [],
      sessionClass: {
        course_session: {}
      }
    };
  },
  async created() {
    await this.initialCustomLesson();
    await this.fetchReport();
    const res = await profileApi.getUserData(this.studentUserId);
    this.student = res.student;
    this.loading = true;
  },
  methods: {
    async fetchReport() {
      const {
        student_progress_reports_group_by_user: studentReports
      } = await reportApi.fetchReports(this.$route.params.id);
      this.studentReports = studentReports;
    },
    async initialCustomLesson() {
      const customLesson = await privateApi.getCustomLesson({
        class_id: this.$route.params.id
      });
      this.sessionClass = {
        ...customLesson.session_class
      };

      this.students = customLesson.student_class_tickets;
      this.lessonLogs = customLesson.lesson_logs_group_by_user;
    }
  }
};
</script>
<style scoped>
.survey-tip {
  margin-bottom: 2rem;
}
::v-deep .el-alert {
  font-size: 16px;
  line-height: 1.5;
  border-radius: 5px;
  /* border: 1px solid #52c41a; */
  /* background-color: #f6ffed;
  color: #333; */
  padding: 15px;
}
::v-deep .el-alert__title {
  font-weight: bold;
}
::v-deep .el-alert__icon {
  font-size: 24px;
  margin-right: 10px;
  /* color: #52c41a; */
}
::v-deep .el-alert__description {
  font-size: 16px !important;
}
.survey-link {
  font-weight: bold;
  text-decoration: underline;
  /* margin-left: 0.5rem; */
}
.survey-questions h2 {
  font-size: 18px;
  margin-bottom: 5px;
}
.survey-questions p {
  color: #838383;
  font-size: 14px;
  line-height: 16px;
}
</style>
